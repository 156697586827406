import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useScroll } from 'framer-motion';
import { ArrowRight, Signal, Shield, Globe, Mail } from 'lucide-react';

const HomePage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { scrollY } = useScroll();
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    setIsVisible(true);

    const unsubscribe = scrollY.on("change", latest => {
      if (latest > 100 && !hasScrolled) {
        setHasScrolled(true);
      }

      const threshold = window.innerHeight * 0.3;
      const shouldShow = latest > threshold && hasScrolled;
      
      if (isNavVisible !== shouldShow) {
        setIsNavVisible(shouldShow);
      }
    });

    return () => unsubscribe();
  }, [scrollY, hasScrolled, isNavVisible]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['news', 'services', 'about'];
      const current = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 150 && rect.bottom >= 150;
        }
        return false;
      });
      
      if (current) {
        setActiveSection(current);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navLinkClass = (section) => `
    text-gray-600 hover:text-emerald-600 transition-colors
    ${activeSection === section ? 'text-emerald-600 font-medium' : ''}
  `;

  return (
    <div className="min-h-screen bg-white pb-[120px]">
      <section className="relative h-screen flex items-center justify-center bg-gradient-to-r from-emerald-900 via-emerald-600 to-emerald-400 text-white overflow-hidden px-4">
        <div className="absolute inset-0">
          {[...Array(20)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute h-[2px] w-[100px] bg-emerald-300/30"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
              }}
              animate={{
                x: [0, 100, 0],
                opacity: [0.5, 1, 0.5],
              }}
              transition={{
                duration: Math.random() * 3 + 2,
                repeat: Infinity,
                ease: "linear",
              }}
            />
          ))}
        </div>

        <div className="absolute inset-0 flex items-center justify-center">
          <motion.div
            className="w-[300px] md:w-[600px] h-[300px] md:h-[600px] border border-emerald-500/20 rounded-full"
            animate={{ rotate: 360 }}
            transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
          />
          <motion.div
            className="absolute w-[200px] md:w-[400px] h-[200px] md:h-[400px] border border-emerald-500/30 rounded-full"
            animate={{ rotate: -360 }}
            transition={{ duration: 15, repeat: Infinity, ease: "linear" }}
          />
        </div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
          transition={{ duration: 0.8 }}
          className="z-10 text-center relative max-w-4xl mx-auto"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4 md:mb-6 tracking-wider relative">
              <span className="relative">
                Link Mobile
                <motion.span
                  className="absolute -inset-2 bg-emerald-500/20 -z-10 blur-lg"
                  animate={{
                    opacity: [0.5, 1, 0.5],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                  }}
                />
              </span>
            </h1>
          </motion.div>

          <motion.p
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="text-base md:text-xl mb-6 md:mb-8 text-emerald-100 px-4 md:px-0"
          >
            次世代の通信インフラで、あなたのビジネスをサポート
          </motion.p>

          <motion.button
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-white text-emerald-600 px-6 md:px-8 py-2.5 md:py-3 rounded-full font-semibold hover:bg-emerald-50 transition-all duration-300 flex items-center gap-2 mx-auto group relative overflow-hidden text-sm md:text-base"
            aria-label="事業内容へ移動"
            onClick={() => {
              const targetSection = document.getElementById('services');
              targetSection?.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <span className="relative z-10">詳しく見る</span>
            <ArrowRight className="w-3.5 h-3.5 md:w-4 md:h-4 relative z-10 group-hover:translate-x-1 transition-transform" />
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-emerald-200 to-green-100"
              initial={{ x: "100%" }}
              whileHover={{ x: 0 }}
              transition={{ duration: 0.3 }}
            />
          </motion.button>
        </motion.div>

        <motion.div
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer"
          animate={{
            y: [0, 10, 0],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut",
          }}
          onClick={() => {
            const newsSection = document.getElementById('news');
            newsSection?.scrollIntoView({ behavior: 'smooth' });
          }}
          aria-label="下にスクロール"
          role="button"
          tabIndex={0}
        >
          <div className="w-6 h-10 border-2 border-white rounded-full flex justify-center">
            <motion.div
              className="w-1 h-2 bg-white rounded-full mt-2"
              animate={{
                y: [0, 12, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
          </div>
        </motion.div>
      </section>

      <section id="news" className="py-24 px-8">
        <div className="max-w-6xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-4">お知らせ</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              最新の情報をお届けします
            </p>
          </motion.div>
          <div className="space-y-6">
            {[
              { date: "2024.11.01", title: "会社ホームページをリニューアルしました。" },
              // 必要に応じてニュースを追加
            ].map((news, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="flex items-center gap-8 p-6 bg-white rounded-lg hover:shadow-md transition-shadow duration-300 border border-gray-100"
              >
                <span className="text-emerald-600 font-medium">{news.date}</span>
                <h3 className="text-lg">{news.title}</h3>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section id="services" className="py-24 px-8 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-4">事業内容</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              最新の技術と豊富な経験を活かし、お客様のビジネスに最適なソリューションを提供します。
            </p>
          </motion.div>
          
          <div className="grid md:grid-cols-3 gap-8 md:gap-12">
            {[
              {
                icon: <Signal className="w-12 h-12" />,
                title: "通信事業",
                description: "高品質な通信サービスを、柔軟な料金プランで提供します。",
                features: ["ネットワーク設備", "Wifi・モバイル端末", "5G対応MVNO回線"]
              },
              {
                icon: <Shield className="w-12 h-12" />,
                title: "システム開発",
                description: "お客様のビジネスに合わせたシステム開発を行います。",
                features: ["フルスタック開発", "ECサイト構築", "アフターサポート"]
              },
              {
                icon: <Globe className="w-12 h-12" />,
                title: "パートナー事業",
                description: "提携企業さまに最適な環境を提供します。",
                features: ["光回線", "モバイル回線", "OEM提供"]
              },
            ].map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="relative group h-full"
              >
                <div className="bg-white p-8 rounded-xl shadow-sm group-hover:shadow-xl transition-all duration-300 border border-gray-100 h-full flex flex-col">
                  <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-emerald-500 to-green-300 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
                  <div className="text-emerald-600 mb-6">{service.icon}</div>
                  <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  <ul className="space-y-3 mt-auto">
                    {service.features.map((feature, i) => (
                      <li key={i} className="flex items-center text-gray-600">
                        <div className="w-1.5 h-1.5 rounded-full bg-emerald-500 mr-3"></div>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section id="about" className="py-24 px-8 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-4">会社概要</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              私たちは、次世代の通信技術を活用し、お客様のビジネスの成長をサポートします。
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="bg-white rounded-xl shadow-sm p-8 border border-gray-100"
            >
              <table className="w-full">
                <tbody className="divide-y divide-gray-100">
                  {[
                    { label: "会社名", value: "LinkMobile株式会社" },
                    { label: "設立", value: "2022年4月" },
                    { label: "所在地", value: "〒329-2712\n栃木県那須塩原市下永田2丁目1051番1" },
                    { label: "事業内容", value: "・通信事業\n・システム開発事業\n・パートナー事業" },
                    { label: "許認可", value: "届出電気通信事業者:A-04-19626\n媒介等業務受託者届出番号:C2214264" },
                  ].map((item, index) => (
                    <tr key={index}>
                      <th className="py-4 text-left text-gray-600 align-top w-1/3">{item.label}</th>
                      <td className="py-4 text-gray-900 whitespace-pre-line">{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </motion.div>

            <div className="space-y-8">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
                className="bg-white rounded-xl shadow-sm p-8 border border-gray-100"
              >
                <h3 className="text-xl font-bold mb-4 text-emerald-600">企業理念</h3>
                <p className="text-gray-600 leading-relaxed">
                  テクノロジーの力で、人々の暮らしをより豊かに。私たちは、革新的な通信サービスとシステムソリューションを通じて、
                  持続可能な社会の実現に貢献します。
                </p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="bg-white rounded-xl shadow-sm p-8 border border-gray-100"
              >
                <h3 className="text-xl font-bold mb-4 text-emerald-600">アクセス</h3>
                <div className="aspect-video w-full bg-gray-100 rounded-lg mb-4">
                  <iframe 
                    title="会社所在地の地図"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.445152319523!2d139.99375687627253!3d36.87969766321341!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601f802246265dd9%3A0x8e9f14b8e4e09987!2z44CSMzI5LTI3MTIg5qCD5pyo55yM6YKj6aCI5aGp5Y6f5biC5LiL5rC455Sw77yS5LiB55uu77yR77yQ77yV77yR4oiS77yR!5e0!3m2!1sja!2sjp!4v1730703669815!5m2!1sja!2sjp"
                    className="w-full h-full rounded-lg"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <AnimatePresence>
        {isNavVisible && (
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed bottom-0 left-0 right-0 z-50"
          >
            <div className="bg-white/80 backdrop-blur-lg border-t border-gray-200 shadow-lg px-4 py-3">
              <div className="max-w-6xl mx-auto">
                <div className="md:hidden">
                  <div className="flex flex-col gap-3 pb-safe"> {/* iOS の安全領域に対応 */}
                    <div className="flex justify-center gap-8 px-4">
                      <a href="#news" className={`${navLinkClass('news')} text-sm`}>
                        お知らせ
                      </a>
                      <a href="#services" className={`${navLinkClass('services')} text-sm`}>
                        事業内容
                      </a>
                      <a href="#about" className={`${navLinkClass('about')} text-sm`}>
                        会社概要
                      </a>
                    </div>
                    <div className="flex px-4">
                      <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className="flex-1 bg-emerald-600 text-white px-4 py-2.5 rounded-full text-sm font-medium hover:bg-emerald-700 transition-colors duration-300 flex items-center justify-center gap-1.5 shadow-sm"
                      >
                        <Mail className="w-3.5 h-3.5" />
                        <span>お問い合わせ</span>
                      </motion.button>
                    </div>
                  </div>
                </div>

                <div className="hidden md:flex justify-between items-center">
                  <div className="flex gap-8">
                    <a href="#news" className={`${navLinkClass('news')}`}>
                      お知らせ
                    </a>
                    <a href="#services" className={`${navLinkClass('services')}`}>
                      事業内容
                    </a>
                    <a href="#about" className={`${navLinkClass('about')}`}>
                      会社概要
                    </a>
                  </div>

                  <div className="flex gap-4">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-emerald-600 text-white px-6 py-2 rounded-full font-medium hover:bg-emerald-700 transition-colors duration-300 flex items-center gap-2"
                    >
                      <Mail className="w-4 h-4" />
                      お問い合わせ
                    </motion.button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <footer className="py-4 px-8 text-center text-gray-600 border-t border-gray-200 bg-white">
        <p>&copy; 2022 LinkMobile Co., Ltd.</p>
      </footer>
    </div>
  );
};

export default HomePage;